
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";
  import { TextValuePair } from "@/interfaces";

  const locationsStore = namespace('locations');

  @Component
  export default class CountriesFilter extends Vue {
    @Prop({ default: true }) readonly dense!: boolean;
    @Prop({ default: 'locations.filterCountry' }) readonly label!: string;
    @Prop({ default: () => ([]) }) readonly selectedValues!: string[];

    @locationsStore.State
    readonly countries!: string[]

    get items(): TextValuePair[] {
      return this.countries?.map(country => ({
        text: this.$t(`locations.countries.${ country }`) as string,
        value: country
      }))
    }
  }
