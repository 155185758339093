
  import { Component, Vue } from 'vue-property-decorator';
  import { namespace } from 'vuex-class';
  import { DataTableHeader } from 'vuetify';
  import { Restaurant, RestaurantType, TextValuePair } from '@/interfaces';
  import { filter } from '@/helpers/filter';
  import RestaurantModal from '@/components/modals/Restaurant.vue';
  import CountriesFilter from '@/components/filters/Countries.vue';

  const restaurantsStore = namespace('restaurants');

  @Component({ components: { CountriesFilter, RestaurantModal } })
  export default class RestaurantsPage extends Vue {
    showModal = false;
    itemToEdit: Restaurant | null = null;

    typeFilter: RestaurantType[] = [];
    countryFilter: string[] = [];

    @restaurantsStore.Getter('getAllRestaurants')
    readonly restaurants!: Restaurant[];

    mounted(): void {
      this.getRestaurants();
    }

    @restaurantsStore.Action
    getRestaurants!: () => Restaurant[];

    @restaurantsStore.Action
    removeRestaurant!: (id: number) => Promise<void>;

    @restaurantsStore.Action
    updateRestaurant!: (data: Restaurant) => Promise<Restaurant | undefined>;

    @restaurantsStore.Action
    addRestaurant!: (data: Partial<Restaurant>) => Promise<void>;

    get filtredRestaurants(): Restaurant[] {
      return filter(this.restaurants, {
        type: this.typeFilter,
        country: this.countryFilter
      });
    }

    get headers(): DataTableHeader[] {
      return [
        { value: 'name', text: this.$t('restaurants.name') as string },
        { value: 'type', text: this.$t('general.type') as string },
        { value: 'ops', text: '', align: 'end' },
      ]
    }

    get typesLabels(): Record<string, string> {
      return (this.$t('restaurants.restaurantTypes') as unknown as  TextValuePair[])
        .map((item: TextValuePair) => ({ [item.value]: ''+item.text }))
        .reduce((all, item) => ({ ...all, ...item }), {});
    }

    add(): void {
      this.itemToEdit = null;
      this.showModal = true;
    }

    edit(item: Restaurant): void {
      this.itemToEdit = item;
      this.showModal = true;
    }

    submit(data: Partial<Restaurant>): void {
      data.id ? this.updateRestaurant(data as Restaurant) : this.addRestaurant(data)
    }

    remove(restaurantId: number): void {
      if (confirm(this.$t('restaurants.confirmRemove') as string)) {
        this.removeRestaurant(restaurantId);
      }
    }
  }
