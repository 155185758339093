export const isRequired = (val: unknown): boolean => !!(Array.isArray(val) ? val.length : val);

export const isEmail = (val: string): boolean => {
  const regExp = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return regExp.test(val);
};

export const isTime = (val: string): boolean => /^\d{1,2}:\d{2}$/.test(val);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isCSVFile = (file: any): boolean => (file && file.type === 'text/csv') || false;

export const isValidatStringNumber = (val: string): boolean => !isNaN(+val);

export const isValidUUID = (val: string): boolean => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089abcd][0-9a-f]{3}-[0-9a-f]{12}$/i.test(val);

export const isValidStavaStoreId = (val: string): boolean => /^[0-9a-f]{32,40}$/.test(val);

export const isValidBoltStoreId = (val: string): boolean => /^[A-z0-9]{10}$/.test(val);

export const isValidWoltStoreId = (val: string): boolean => /^[A-f0-9]{24}$/.test(val);

export const isValidUrl = (val: string): boolean => {
  try {
    new URL(val);
    return true;
  } catch(e) {
    return false;
  }
};

export const isPhone = (val: string): boolean => /^([+0-9]+$)/.test(val);
