import { Component, Prop, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { isRequired, isEmail, isTime } from '@/helpers/validations';
import { TextValuePair } from '@/interfaces';

const locationsStore = namespace('locations');

@Component
export default class FormModal extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Prop({ default: () => ({}) }) readonly item!: any;

  protected visible = true;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected data: any = {};
  protected isValid = false;

  get editMode(): boolean {
    return this.item && this.item.id !== undefined;
  }

  get countries(): TextValuePair[] {
    return this.countriesList.map((country) => ({
      value: country,
      text: this.$t(`locations.countries.${country}`) as string,
    }));
  }

  get languages(): TextValuePair[] {
    const languages = ['pl', 'ua', 'en'];

    return languages.map((lang) => ({
      value: lang,
      text: this.$t(`locations.languages.${lang}`) as string,
    }));
  }

  @locationsStore.State('countries')
  readonly countriesList!: string[];

  mounted(): void {
    this.copyItemToData();
  }

  copyItemToData(newitem: unknown = null): void {
    const data = newitem ? newitem : this.item;
    this.data = Array.isArray(data) ? [...data] : { ...data };
  }

  save(): void {
    this.$emit('submit', this.data);
    this.closeModal();
  }

  closeModal(): void {
    this.$emit('close');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isRequired(value: any): boolean {
    return isRequired(value);
  }

  isEmail(value: string): boolean {
    return isEmail(value);
  }

  isTime(value: string): boolean {
    return isTime(value);
  }
}
