
  import { Resource, ResourceType, TextValuePair } from "@/interfaces";
  import { Component, Prop, Vue } from "vue-property-decorator";
  import { namespace } from "vuex-class";

  const resourcesStore = namespace('resources');

  @Component
  export default class ResourcesFilter extends Vue {
    @Prop({ default: 'users.limitations' }) readonly label!: string;
    @Prop({ default: null }) readonly country!: string;
    @Prop({ required: true }) readonly type!: ResourceType;

    @resourcesStore.State('resources')
    resources!: Resource[]

    get items(): TextValuePair[] {
      const items = this.country
        ? this.resources.filter(resource => resource.country === this.country)
        : this.resources;

      return items.map(item => ({
        text: item.name,
        value: item.id,
      }));
    }

    mounted(): void {
      this.getResources(this.type);
    }

    @resourcesStore.Action
    getResources!: (type: ResourceType) => Promise<void>;
  }
