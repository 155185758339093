export function filter<T>(models: T[], filters: Record<string, string[]>): T[] {
  if (!Object.keys(filters).length) {
    return models;
  }

  for (const fieldToFilter in filters ) {
    const filtersToApply = filters[fieldToFilter];

    if (filtersToApply.length) {
      models = models.filter(item => filtersToApply.includes(item[fieldToFilter]));
    }
  }

  return models;
}
